<template>
  <div>
    <router-link class="come-back" to="/admin/nko_partners">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование партнера' : 'Создание партнера' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createOnkPartners" ref="createOnkPartners">
        <div class="content-sb">
          <p>Название партнера<span>*</span></p>
          <input required placeholder="Введите название документа" v-model="form.title" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Ссылка<span>*</span></p>
          <input required placeholder="Введите ссылку" v-model="form.link" type="text" name="link" class="input blue">
        </div>
        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Старый документ</p>
          <a target="_blank" :href="form.image">Ссылка</a>
        </div>
        <div class="content-sb">
          <p>Документ<span>*</span></p>
          <input :required="$route.query.type !== 'edit'"  type="file" name="image"  class="input blue">
        </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/nko_partners" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createOnkPartners',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailNkoPartners : {
        title: '',
        link: ''
      }
    }
  },
  methods: {
    createOnkPartners () {
      const formData = new FormData(this.$refs.createOnkPartners)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editNkoPartners', { id: this.form.id, data: formData })
      } else {
        this.$store.dispatch('AddNkoPartners', formData)
      }
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailNkoPartners === null) {
      this.$router.push('/admin/nko_partners')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
